import Keycloak from "keycloak-js";
import { IConfig } from "@/api/AuthorizedApiBase";
import { Result } from "@/api/APIClient";
import {
    ADMIN_API_URL,
    isDevelopmentMode,
    isProductionMode,
    KEYCLOAK_CLIENT_ID,
    KEYCLOAK_REALM,
    KEYCLOAK_URL,
} from "@/utils/enviroment";

const keyCloak = new Keycloak({
    realm: KEYCLOAK_REALM || "",
    clientId: KEYCLOAK_CLIENT_ID || "",
    url: KEYCLOAK_URL || "",
});

let isInitialized = false;

const initKeycloak = async () => {
    if (!isInitialized) {
        await keyCloak.init({
            silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
            pkceMethod: "S256",
            enableLogging: !isProductionMode(),
            onLoad: isDevelopmentMode() ? "login-required" : "check-sso",
        });

        isInitialized = true;
    }

    if (!keyCloak.authenticated) {
        keyCloak.login();
    }
};

const doLogout = async () => {
    await initKeycloak();
    keyCloak.logout();
};

const getToken = async () => {
    try {
        if (window.navigator.onLine) {
            await initKeycloak();
            await keyCloak.updateToken(5);
            return keyCloak.token;
        }
    } catch (e) {
        console.error("Failed to refresh token", e);
    }

    return undefined;
};

const getAuthorizationHeader = async () => {
    const token = await getToken();
    return `Bearer ${token}`;
};

const isIRancherAdmin = () => keyCloak.hasResourceRole("administrator", "irancher");

let userToImpersonate: string | undefined;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const userFromQuery = urlParams.get("impersonateUser");

if (userFromQuery !== null) {
    userToImpersonate = userFromQuery;
}

if (typeof Storage !== "undefined") {
    if (userToImpersonate === undefined) {
        const userFromStorage = sessionStorage.getItem("impersonateUser");
        if (userFromStorage !== null) {
            userToImpersonate = userFromStorage;
        }
    } else {
        sessionStorage.setItem("impersonateUser", userToImpersonate);
    }
}

const getUserToImpersonate = (): string | undefined => userToImpersonate;

const LAST_USER_ID = "LAST_USER_ID";

const getCurrentUserId = (): string => {
    if (userToImpersonate !== undefined) {
        return userToImpersonate;
    }

    if (isInitialized && keyCloak.tokenParsed?.sub !== undefined) {
        window.localStorage.setItem(LAST_USER_ID, keyCloak.tokenParsed.sub);
        return keyCloak.tokenParsed.sub;
    }

    const lastUserId = window.localStorage.getItem(LAST_USER_ID);
    if (lastUserId !== null) {
        return lastUserId;
    }

    return "";
};

interface UserAccess {
    accessToken: string;
    expiresIn: number;
}

interface ResultOfUserAccess extends Result {
    data: UserAccess;
}

let tokenToImpersonate: string | undefined;
let tokenToImpersonateValidTo: Date | undefined;
const getImpersonatedToken = async (userId: string) => {
    if (tokenToImpersonateValidTo === undefined || new Date() > tokenToImpersonateValidTo) {
        const options: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: await getAuthorizationHeader(),
            },
        };

        const response = await fetch(`${ADMIN_API_URL}/api/PlatformUsers/impersonate/${userId}`, options);
        if (response.status !== 200) {
            throw new Error(
                `Response from impersonation is not OK (200), was ${response.statusText} (${response.status})`
            );
        }

        const text = await response.text();
        const resultOfUserAccess = JSON.parse(text) as ResultOfUserAccess;

        tokenToImpersonate = resultOfUserAccess.data.accessToken;
        tokenToImpersonateValidTo = new Date(Date.now() + (resultOfUserAccess.data.expiresIn - 5) * 1000);
    }

    return `Bearer ${tokenToImpersonate}`;
};

const getAuthHeader = () => {
    const user = getUserToImpersonate();
    if (user !== undefined) {
        return getImpersonatedToken(user);
    }
    return getAuthorizationHeader();
};

const APIConfig: IConfig = {
    getAuthorization: getAuthHeader,
};

const UserService = {
    initKeycloak,
    doLogout,
    getToken,
    isIRancherAdmin,
    APIConfig,
    getCurrentUserId,
};

export default UserService;
