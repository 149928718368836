import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import UserService from "./auth/UserService";

import "./index.css";

const App = React.lazy(() => import("./App"));

const renderApp = () => {
    const container = document.getElementById("root");
    if (container) {
        const root = createRoot(container);
        root.render(
            <StrictMode>
                <App />
            </StrictMode>
        );
    }
};

if (!window.navigator.onLine) {
    renderApp();
} else {
    UserService.initKeycloak()
        .then(() => renderApp())
        .catch(console.error);
}
