export const APP_VERSION = import.meta.env.VITE_APP_VERSION as string | undefined;
export const BUILD_VER = import.meta.env.VITE_APP_BUILD_VERSION as string | undefined;
export const IS_PRODUCTION = import.meta.env.PROD as boolean;
export const MODE = import.meta.env.MODE as string;

export const productionMode = "production";
export const developmentMode = "development";
export const stagingMode = "staging";
export const demoMode = "demo";

export const isProductionMode = () => MODE === productionMode;
export const isDevelopmentMode = () => MODE === developmentMode;
export const isStagingMode = () => MODE === stagingMode;
export const isDemoMode = () => MODE === demoMode;

export const ADMIN_API_URL = import.meta.env.VITE_ADMIN_API_URL as string | undefined;

export const API_URL = import.meta.env.VITE_API_URL as string | undefined;
export const CDN_URL = import.meta.env.VITE_CDN_URL as string | undefined;

export const KEYCLOAK_REALM = import.meta.env.VITE_KEYCLOAK_REALM as string | undefined;
export const KEYCLOAK_CLIENT_ID = import.meta.env.VITE_KEYCLOAK_CLIENT_ID as string | undefined;
export const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL as string | undefined;

export const MAPBOX_API_KEY = import.meta.env.VITE_MAPBOX_API_KEY as string | undefined;

export const FIREBASE_MESSAGING_KEY = import.meta.env.VITE_FIREBASE_MESSAGING_KEY as string | undefined;
export const FIREBASE_API_KEY = import.meta.env.VITE_FIREBASE_API_KEY as string | undefined;
export const FIREBASE_AUTH_DOMAIN = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN as string | undefined;
export const FIREBASE_PROJECT_ID = import.meta.env.VITE_FIREBASE_PROJECT_ID as string | undefined;
export const FIREBASE_STORAGE_BUCKET = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET as string | undefined;
export const FIREBASE_MESSAGING_SENDER_ID = import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID as string | undefined;
export const FIREBASE_APP_ID = import.meta.env.VITE_FIREBASE_APP_ID as string | undefined;
export const FIREBASE_MEASUREMENT_ID = import.meta.env.VITE_FIREBASE_MEASUREMENT_ID as string | undefined;

export const Colors = {
    primary: "#74A556",
    primaryRgb: "116, 165, 86",
    primaryLight: "#c8f591",
    primaryLightRgb: "200, 245, 145",
    black: "#303030",
    blackRgb: "48, 48, 48",
    red: "#C40909",
    redRgb: "196, 9, 9",
    lightgrey: "#D2D4D4",
    lightgreyRgb: "210, 212, 212",
    warning: "#e5c046",
    warningRgb: "229, 192, 70",
};
